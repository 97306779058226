* {
  margin: 0;
  padding: 0;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  *:focus {
    outline: none; }

html, body {
  height: 100vh; }

a {
  color: inherit;
  text-decoration: inherit;
  border-bottom: solid 1px transparent;
  transition: border-color 0.4s ease-out;
  will-change: border-color; }
  a:hover {
    border-color: #ffffff; }

body {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  background: #00010D;
  color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

body::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

body::-webkit-scrollbar-thumb {
  background: #666; }

body::-webkit-scrollbar-track, body::-webkit-scrollbar-corner, body::-webkit-resizer {
  background: #333; }

@keyframes mount {
  from {
    opacity: 0;
    transform: translateY(-5%); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes routing {
  from {
    opacity: 1;
    transform: scale(1); }
  to {
    opacity: 0;
    transform: scale(0.95); } }

#nest {
  will-change: opacity, transform; }
  #nest.mount {
    animation: mount 0.4s ease-out; }
  #nest.routing {
    animation: routing 0.4s ease-out forwards; }

.footer, #Home .head {
  width: 86%; }

.footer, #Home .head, #Home .projects, #Home .contact, #ProjectDetails .cover .coverNav, #ProjectDetails .coverInfo, #ProjectDetails .content {
  padding-left: 7%;
  padding-right: 7%; }

.footer, #Home .head, #Home .projects, #Home .contactTitle, #ProjectDetails {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.footer {
  display: table;
  font-size: 2em;
  font-weight: 100;
  text-align: center;
  margin: 150px 0 60px 0; }

#Home #background {
  z-index: -100;
  position: absolute;
  width: 100%; }
  #Home #backgroundSolid {
    width: 100%;
    height: 85vh;
    background: #000436; }
  #Home #backgroundWave {
    width: 100%;
    fill: #000436; }

#Home .head {
  display: table;
  padding-top: 60px; }
  #Home .head .headLeft, #Home .head .headRight {
    display: table-row;
    vertical-align: middle; }
  #Home .headLeftTitle {
    font-size: 4em;
    font-weight: 700; }
  #Home .headLeftText {
    margin-top: 15px;
    font-size: 2em;
    font-weight: 100;
    line-height: 1.5em; }
  #Home .headLeftSocial {
    margin-top: 30px;
    display: flex;
    font-size: 3em; }
    #Home .headLeftSocial img {
      width: 1em;
      height: 1em; }
    #Home .headLeftSocial .icon {
      margin-right: 22.5px; }
  #Home .headRight {
    transform: translateX(35%); }
    #Home .headRightCode {
      margin-top: 30px;
      width: 100%; }

#Home .projects {
  margin-top: 150px; }
  #Home .projectsTitle {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 60px; }
  #Home .projectsFlow {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 60px;
    justify-content: space-between; }
  #Home .projectsItem {
    position: relative;
    height: 300px;
    background: #ffffff;
    border-radius: 50px;
    cursor: pointer; }
    #Home .projectsItem img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50px; }
    #Home .projectsItemTitle {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      line-height: 60px;
      background: rgba(0, 4, 54, 0.9);
      font-size: 1.25em;
      text-align: center;
      border-radius: 50px; }
      #Home .projectsItemTitleInit {
        opacity: 1;
        transition: opacity 0.4s ease-out;
        will-change: opacity; }
      #Home .projectsItemTitleGo {
        position: absolute;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 100%;
        opacity: 1;
        background: #00010D;
        border-radius: 50px;
        transition: width 0.4s ease-out, opacity 0.4s ease-out;
        will-change: width, opacity; }
        #Home .projectsItemTitleGo i {
          font-size: 1.5em; }
          #Home .projectsItemTitleGo i:after {
            content: "";
            height: 100%; }
    #Home .projectsItem:hover .projectsItemTitleInit {
      opacity: 0; }
    #Home .projectsItem:hover .projectsItemTitleGo {
      width: 100%;
      opacity: 1; }

#Home .contact {
  margin-top: 150px; }
  #Home .contactTitle {
    font-size: 2em;
    font-weight: 700; }
  #Home .contactText {
    margin-top: 30px;
    font-size: 2em;
    font-weight: 100;
    line-height: 1.5em; }

#ProjectDetails {
  min-height: 100vh; }
  #ProjectDetails .cover, #ProjectDetails .cover img, #ProjectDetails .coverTint, #ProjectDetails .coverTint rect {
    width: 100%;
    height: 60vh; }
  #ProjectDetails .cover {
    position: relative; }
    #ProjectDetails .cover img {
      position: absolute;
      object-fit: cover;
      object-position: 0 0; }
    #ProjectDetails .coverTint {
      position: absolute; }
    #ProjectDetails .cover .coverNav {
      padding-top: 30px; }
      #ProjectDetails .cover .coverNavBack {
        z-index: 100;
        display: inline-block;
        position: relative;
        line-height: 60px;
        padding: 0 25px 0 70px;
        background: rgba(0, 4, 54, 0.75);
        font-size: 1.25em;
        text-align: center;
        border-radius: 50px;
        cursor: pointer; }
        #ProjectDetails .cover .coverNavBackInit {
          opacity: 1;
          transition: opacity 0.4s ease-out;
          will-change: opacity; }
        #ProjectDetails .cover .coverNavBackGo {
          position: absolute;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 100%;
          opacity: 1;
          background: #00010D;
          border-radius: 50px;
          transition: width 0.4s ease-out, opacity 0.4s ease-out;
          will-change: width, opacity; }
          #ProjectDetails .cover .coverNavBackGo i {
            font-size: 1.5em; }
            #ProjectDetails .cover .coverNavBackGo i:after {
              content: "";
              height: 100%; }
        #ProjectDetails .cover .coverNavBack:hover .coverNavBackInit {
          opacity: 0; }
        #ProjectDetails .cover .coverNavBack:hover .coverNavBackGo {
          width: 100%;
          opacity: 1; }
    #ProjectDetails .coverInfo {
      position: absolute;
      bottom: 5vh; }
      #ProjectDetails .coverInfo .title {
        margin-top: 150px;
        font-size: 4em;
        font-weight: 700; }
      #ProjectDetails .coverInfo .url {
        margin-top: 15px;
        font-size: 2em;
        font-weight: 100;
        line-height: 1.5em; }
  #ProjectDetails .content .desc {
    margin-top: 100px; }
    #ProjectDetails .content .descTitle {
      font-size: 2em;
      font-weight: 700; }
    #ProjectDetails .content .descText {
      margin-top: 60px;
      font-size: 2em;
      font-weight: 100;
      line-height: 1.5em; }
  #ProjectDetails .content .images {
    margin-top: 100px; }
    #ProjectDetails .content .imagesTitle {
      font-size: 2em;
      font-weight: 700; }
    #ProjectDetails .content .imagesFlow {
      margin-top: 60px;
      display: grid;
      grid-template-columns: 100%;
      grid-row-gap: 60px;
      justify-content: space-between; }
      #ProjectDetails .content .imagesFlow img {
        width: 100%;
        border-radius: 8px;
        cursor: pointer; }

@keyframes mountZoom {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mountZoomImg {
  from {
    transform: translateY(10%) scale(0.9); }
  to {
    transform: translateY(0) scale(1); } }
  #ProjectDetails #zoom {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 1, 13, 0.8);
    padding: 0 5px 0 5px;
    animation: mountZoom 0.4s ease-out;
    cursor: pointer; }
    #ProjectDetails #zoom.active {
      display: flex;
      justify-content: center;
      align-items: center; }
    #ProjectDetails #zoomImg {
      max-width: 100%;
      max-height: 100%;
      border-radius: 8px;
      animation: mountZoomImg 0.4s ease-out; }

@media only screen and (min-width: 370px) {
  body {
    font-size: 14px; }
  .footer, #Home .head {
    width: 80%; }
  .footer, #Home .head, #Home .projects, #Home .contact, #ProjectDetails .cover .coverNav, #ProjectDetails .coverInfo, #ProjectDetails .content {
    padding-left: 10%;
    padding-right: 10%; }
  #Home .projectsItem {
    height: 325px; } }

@media only screen and (min-width: 852px) {
  .footer, #Home .head {
    width: 70%; }
  .footer, #Home .head, #Home .projects, #Home .contact, #ProjectDetails .cover .coverNav, #ProjectDetails .coverInfo, #ProjectDetails .content {
    padding-left: 15%;
    padding-right: 15%; }
  #Home #backgroundSolid {
    height: 30vh; }
  #Home .head {
    padding-top: 100px; }
    #Home .head .headLeft, #Home .head .headRight {
      display: table-cell;
      width: 50%; }
    #Home .headLeft {
      padding: 0 5% 7% 0; }
    #Home .headRight {
      transform: none;
      text-align: right; }
      #Home .headRightCode {
        margin-top: 0; }
  #Home .projectsFlow {
    grid-template-columns: 45% 45%; }
  #Home .projectsItem {
    height: 400px; }
    #Home .projectsItemTitle {
      line-height: 75px; }
      #Home .projectsItemTitleGo {
        width: 25%; }
  #ProjectDetails .cover .coverNavBack {
    line-height: 75px;
    padding: 0 50px 0 100px; }
    #ProjectDetails .cover .coverNavBackGo {
      width: 75px; }
  #ProjectDetails .content .imagesFlow {
    grid-template-columns: 49% 49%; } }

@media only screen and (min-width: 1600px) {
  #Home .projectsFlow {
    grid-template-columns: 30% 30% 30%; } }
